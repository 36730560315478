import React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";
import { graphql } from "gatsby";
import Raw from "components/raw";
import { buildReplaceForStandalonePage } from "utils/html";

export const query = graphql`
    query($id: String!, $language: String!) {
        ...seo
        lang(key: $language) {
            ...footer
            misc {
                menu {
                    menuItems {
                        title
                        url
                    }
                }
            }
        }
        current: allMarkdownRemark(filter: { id: { eq: $id } }) {
            edges {
                node {
                    html
                    frontmatter {
                        description
                        title
                    }
                    translation {
                        frontmatter {
                            language
                        }
                        fields {
                            path
                        }
                    }
                    media {
                        relativePath
                        childImageSharp {
                            fluid(quality: 100) {
                                ...GatsbyImageSharpFluid_noBase64
                                ...GatsbyImageSharpFluidLimitPresentationSize
                            }
                        }
                    }
                }
            }
        }
    }
`;

export default function MiscPageTemplate({
    data: {
        seo,
        current,
        lang: {
            misc: { menu },
            footer,
        },
    },
    pageContext: { language },
}) {
    const {
        frontmatter: { title, description },
        html,
        media,
        translation,
    } = current.edges[0].node;
    const translations = (translation || []).map(t => ({
        key: t.frontmatter.language,
        link: "/" + t.fields.path,
    }));

    return (
        <Layout menu={menu} footer={footer} translations={translations}>
            <SEO
                title={title}
                description={description}
                language={language}
                seo={seo}
            />
            <section className="misc section">
                <div className="container mx-auto">
                    <Raw
                        html={html}
                        replace={buildReplaceForStandalonePage(
                            "",
                            language,
                            media
                        )}
                    />
                </div>
            </section>
        </Layout>
    );
}
